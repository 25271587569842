// frontend/src/components/Navigation/ProfileButton.js

import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import * as sessionActions from '../../store/session';
import './Navigation.css'
import logoutButton from './logoutGrey.svg'

function ProfileButton({ user }) {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  
  const openMenu = () => {
    if (showMenu) return;
    setShowMenu(true);
  };
  
  useEffect(() => {
    if (!showMenu) return;

    const closeMenu = () => {
      setShowMenu(false);
    };

    document.addEventListener('click', closeMenu);
  
    return () => document.removeEventListener("click", closeMenu);
  }, [showMenu]);

  const logout = (e) => {
    e.preventDefault();
    dispatch(sessionActions.logout());
  };

  return (
    <>
      <button className="profileButton" onClick={openMenu}>
        <i className="fa-solid fa-user" style={{color: "white"}} />
      </button>
      {showMenu && (
        <ul className="profile-dropdown">
          <li className="userDetails">Username: <br/> {user.username}</li>
          <li className="userDetails">Email: <br/> {user.email}</li>
          <li>
            <p onClick={logout} className="signOutButton"> <img className="logoutImage" src={logoutButton}/> Sign Out</p>
          </li>
        </ul>
      )}
    </>
  );
}

export default ProfileButton;